import request from "SRC/utils/request";

const DalleAPI = {
  Dalle: "/dalle/api/ImageGeneration",
  dalleHistorySummary: "/dalle/api/History/summary",
  dalleHistoryRecently: "/dalle/api/History/recently",
  dalleHistrotyId: "/dalle/api/History/",
  gptHistorySummary: "/gpt/api/History/summary",
  gptHistoryRecently: "/gpt/api/History/recently",
  gptHistrotyId: "/gpt/api/History/",
  gptApiChat: "gpt/api/Chat",
};

export interface IMessageContent {
  type: string;
  text?: string;
  imageUrl?: {
    url: string;
  };
  fileName?: string;
  attachment?: string
}
export interface IMessage {
  role: string;
  mid?: any;
  content: IMessageContent[];
}
export interface IGptApiChat {
  historyId: string,
  version: string,
  isWebSearch: boolean,
  messages: IMessage[],
  stream: boolean
}

export function getGptApiChat(onDownloadProgress: any, params: IGptApiChat, signal: AbortSignal) {
  return request({
    timeout: 0,
    url: DalleAPI.gptApiChat,
    method: "post",
    data: params,
    responseType: 'stream',
    signal: signal,
    onDownloadProgress
  });
}

export function Dalle(data:any) {
  return request({
    url: DalleAPI.Dalle,
    method: "post",
    data: data,
  });
}

export function dalleHistorySummary() {
  return request({
    url: DalleAPI.dalleHistorySummary,
    method: "get",
  });
}

export function dalleHistoryRecently() {
  return request({
    url: DalleAPI.dalleHistoryRecently,
    method: "get",
  });
}

export function dalleHistoryId(id: string) {
  return request({
    url: DalleAPI.dalleHistrotyId + id,
    method: "get",
  });
}

export function dalleHistoryIdDelete(id: string) {
  return request({
    url: DalleAPI.dalleHistrotyId + id,
    method: "delete",
  });
}

export function dalleHistoryIdContentDelete(id: string,content:string) {
  return request({
    url: DalleAPI.dalleHistrotyId + id + "/content/",
    method: "delete",
    headers: {
      'Content-Type': 'application/json',
    },
    data: JSON.stringify(content)
  });
}

export function gptHistorySummary() {
  return request({
    url: DalleAPI.gptHistorySummary,
    method: "get",
  });
}

export function gptSummaryChange(data) {
  return request({
    url: DalleAPI.gptHistorySummary,
    method: "post",
    data
  })
}

export function gptHistoryRecently() {
  return request({
    url: DalleAPI.gptHistoryRecently,
    method: "get",
  });
}

export function gptHistoryId(id: string) {
  return request({
    url: DalleAPI.gptHistrotyId + id,
    method: "get",
  });
}

export function gptHistoryIdDelete(id: string) {
  return request({
    url: DalleAPI.gptHistrotyId + id,
    method: "delete",
  });
}

